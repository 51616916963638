
import { mapWritableState } from 'pinia';
import { defineComponent } from 'vue';
import { resendVerificationEmail, tryVerifyEmail } from '@/services/steamcord/auth';
import { validateRequired } from '@/services/validation';
import { useAuthStore } from '@/store/authStore';
import Modal from '@/components/Modal.vue';

export default defineComponent({
  components: {
    Modal,
  },
  computed: {
    ...mapWritableState(useAuthStore, ['emailVerified']),
    redirect() {
      const { redirect } = this.$route.meta;
      if (!redirect) {
        return 'Sign In Form';
      }

      return redirect as string;
    },
  },
  data() {
    return {
      resendEmailLoading: false,
      formError: '',
      formLoading: false,
      showModal: false,
      verificationCode: '',
      verificationCodeError: '',
    };
  },
  methods: {
    async onSubmit(): Promise<void> {
      if (this.formLoading) {
        return;
      }

      const error = validateRequired(this.verificationCode);
      if (error) {
        this.verificationCodeError = error;
        return;
      }

      this.formLoading = true;

      const email = this.$route.params.email.toString();

      if (await tryVerifyEmail(email, this.verificationCode)) {
        this.$router.push({ name: this.redirect });
      } else {
        this.verificationCodeError = 'Invalid verification code.';
      }

      // TODO: encapsulate logic in authStore
      this.emailVerified = true;
      localStorage.setItem('emailVerified', true.toString());

      this.formLoading = false;
    },
    async resendEmail() {
      if (this.resendEmailLoading) {
        return;
      }

      this.resendEmailLoading = true;

      const email = this.$route.params.email.toString();

      await resendVerificationEmail(email);

      this.resendEmailLoading = false;

      this.showModal = true;
    },
  },
});
